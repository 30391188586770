export default {
  header: {
    title: 'Caminos de Andalucía (CA)',
  },
  visor: {
    coordinates: 'Coordenadas',
    map: 'Mapa',
    image: 'Imagen',
    street_map: 'Callejero',
    information: 'Información',
  },
};
