import React, { Component } from 'react';

import './Map.css';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      splitViwer: false
    };
  }

  render() {
    return (<div className='visor-wrapper'>
      <div className='map' id='map'>
      </div>
    </div>);
  }
}

export default Map;
