import React, { Component, Fragment } from 'react';
import { translate } from 'react-i18next';
import LocalStorage from 'utils/LocalStorage';

import Logo from 'static/img/logo.png';
import LogoUCO from 'static/img/logo_uco.png';
import LogoCEA from 'static/img/logo_cea.png';

import './Header.css';

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
      langSelected: LocalStorage.getString('language') || 'es',
    };
  }

  changeLanguage = (lang) => {
    this.setState({ langSelected: lang }, () => {
      LocalStorage.putString('language', lang);
      window.location.reload();
    });
  }

  navigate = () => {
    window.location.href = '/visor';
  }

  goTo = (link) => {
    window.open(link);
  }

  render() {
    const { langSelected } = this.state;

    return (<Fragment>
      <header className='h-desktop'>
        <div className='logo-ign'>
          <img className='logo-cea' src={LogoCEA} alt='Logo CEA' onClick={this.goTo.bind(null, 'https://www.centrodeestudiosandaluces.es/')} />
          <img className='logo-uco' src={LogoUCO} alt='Logo UCO' onClick={this.goTo.bind(null, 'https://www.uco.es/')} />
        </div>
        <div className='visor-title'>
          <div className='logo-visor'>
            <img src={Logo} alt='Logo Visor' onClick={this.navigate} />
          </div>
        </div>
        <div className='visor-language'>
          <span className={langSelected === 'es' ? 'lang-selected' : 'lang-option'} onClick={this.changeLanguage.bind(null, 'es')}>es</span>
          <span className={langSelected === 'en' ? 'lang-selected' : 'lang-option'} onClick={this.changeLanguage.bind(null, 'en')}>en</span>
        </div>
      </header>
    </Fragment>);
  }
}

export default translate()(Header);
