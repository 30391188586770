import React from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';

import M from 'utils/Mapea';
import Utils from 'utils/Utils';
import LocalStorage from 'utils/LocalStorage';

import MAPA from 'static/img/mapa.png';
import IMAGEN from 'static/img/image.png';
import RASTER from 'static/img/raster.png';
import DownArrow from 'static/img/down_arrow.png';

declare var ol;
declare var PRECHARGED;
declare var CONFIGURATIONS;
const POINTS = [1, 15];
const LINES = [10, 15];
const LINE_POINTS = [1, 15, 20, 15];

export let mapjs;

export const initMap = (unblock, t, params) => {
  const lang = LocalStorage.getString('language') || 'es';
  M.language.setLang(lang);
  let zoom = 6;
  let center = [-428106.86611520057, 4334472.25393817];
  let mouseProjection = 'EPSG:4326';
  let filter = undefined;
  try {
    const arrayParams = params.split('&');
    arrayParams.forEach((param) => {
      if (param.indexOf('center') > -1) {
        const values = param.split('=')[1].split(',');
        center = [parseFloat(values[0]), parseFloat(values[1])];
      } else if (param.indexOf('zoom') > -1) {
        const value = param.split('=')[1];
        zoom = parseInt(value, 10);
      } else if (param.indexOf('srs') > -1) {
        const value = param.split('=')[1];
        mouseProjection = value;
      } else if (param.indexOf('filter') > -1) {
        const value = param.split('=')[1];
        filter = Utils.normalize(value);
      }
    });

    if (center[0] !== -428106.86611520057 && center[1] !== 4334472.25393817) {
      center = ol.proj.transform(center, mouseProjection, 'EPSG:3857');
    }
  } catch (err) {}

  if (Utils.isMobile()) {
    mapjs = M.map({
      container: 'map',
      controls: ['location', 'rotate'],
      center: center,
      zoom: zoom !== 6 ? zoom : (zoom - 2),
      minZoom: 3,
      maxZoom: 20,
    });

    window.mapjs = mapjs;
    mapjs.addPlugin(new M.plugin.BackImgLayer(getConfiguredBaseLayersPlugin(t, true)));
    mapjs.addPlugin(new M.plugin.Vectors(getConfiguredVectorsPlugin(filter)));
    mapjs.addPlugin(new M.plugin.Information({ position: 'TR' }));
    mapjs.addPlugin(new M.plugin.IGNSearchLocator({
      servicesToSearch: 'gn',
      maxResults: 10,
      isCollapsed: false,
      position: 'TL',
      reverse: true,
    }));

    mapjs.addPlugin(new M.plugin.FullTOC({
      collapsed: true,
      position: 'TR',
      https: true,
      http: true,
      precharged: PRECHARGED,
      mobile: true,
    }));

    const backImgElem = document.querySelector('#map > div > div.ol-overlaycontainer-stopevent > div > div.m-area.m-bottom.m-right > div.m-panel.m-plugin-backimglayer.collapsed');
    backImgElem.position = 'fixed';
    backImgElem.bottom = '60px';
  } else {
    mapjs = M.map({
      container: 'map',
      controls: ['scale*true'],
      center: center,
      zoom: zoom,
      minZoom: 5,
      maxZoom: 20,
    });

    window.mapjs = mapjs;

    mapjs.addPlugin(new M.plugin.BackImgLayer(getConfiguredBaseLayersPlugin(t, false)));
    mapjs.addPlugin(new M.plugin.Information({ position: 'TR' }));
    mapjs.addPlugin(new M.plugin.ZoomPanel({ position: 'TL', collapsed: true }));
    mapjs.addPlugin(new M.plugin.Vectors(getConfiguredVectorsPlugin(filter)));
    mapjs.addPlugin(new M.plugin.FullTOC({
      collapsed: true,
      position: 'TR',
      https: true,
      http: true,
      precharged: PRECHARGED,
    }));

    mapjs.addPlugin(new M.plugin.OverviewMap({
      position: 'BL',
      fixed: true,
      zoom: 4,
      baseLayer: 'WMTS*https://www.ign.es/wmts/mapa-raster?*MTN*GoogleMapsCompatible*Cartografía del IGN*false*image/png*false*false*true',
      collapsed: true,
      collapsible: true,
    }));

    mapjs.addPlugin(new M.plugin.IGNSearchLocator({
      servicesToSearch: 'gn',
      searchPosition: 'geocoder,nomenclator',
      maxResults: 10,
      isCollapsed: false,
      position: 'TL',
      reverse: true,
    }));

    mapjs.addPlugin(new M.plugin.MouseSRS({
      position: 'BL',
      tooltip: t('visor.coordinates'),
      srs: mouseProjection,
      label: mouseProjection,
      precision: 4,
      geoDecimalDigits: 6,
      utmDecimalDigits: 2,
      activeZ: true,
    }));

    let allServices = CONFIGURATIONS.precharged.services || [];
    if (CONFIGURATIONS.precharged.groups !== undefined && CONFIGURATIONS.precharged.groups.length > 0) {
      CONFIGURATIONS.precharged.groups.forEach((group) => {
        allServices = allServices.concat(group.services || []);
      });
    }

    allServices = allServices.filter((s) => {
      return s.loaded === true;
    });

    let promises = [];
    allServices.forEach((s) => {
      promises.push(M.remote.get(s.url));
    });

    Promise.all(promises).then((responses) => {
      responses.forEach((response, index) => {
        const service = allServices[index];
        const fileName = service.url.substring(service.url.lastIndexOf('/') + 1, service.url.lastIndexOf('.'));
        const extension = service.url.substring(service.url.lastIndexOf('.') + 1, service.url.length);
        const features = extension === 'kml' ? loadKMLLayer(response.text, fileName, false, service.name, t) : loadGeoJSONLayer(response.text, fileName, service.name, t);
        if (features.length > 0) {
          manageFeatures(features, service);
        }
      });
    });
  }

  mapjs.getMapImpl().once('postrender', (evt) => {
    unblock();
  });
};

export const isLoadedMap = () => mapjs !== undefined;

export const addWMSLayer = (name, legend, url, version, tiled, options) => {
  const args = {
    name: name,
    url: url,
    legend: legend,
    transparent: true,
    tiled: tiled,
    version: version,
  };

  const layer = new M.layer.WMS(args, options);

  layer.options.visibility = options.visibility;
  layer.options.displayInLayerSwitcher = options.displayInLayerSwitcher;
  layer.options.queryable = options.queryable;

  if (options && options.hasOwnProperty('visibility')) {
    layer.setVisible(options.visibility);
  }

  if (options && options.hasOwnProperty('displayInLayerSwitcher')) {
    layer.displayInLayerSwitcher = options.displayInLayerSwitcher;
  }

  if (options && options.hasOwnProperty('zIndex')) {
    layer.setZIndex(options.zIndex);
  }

  mapjs.addLayers(layer);
  return layer.getImpl().getExtent();
};

const getConfiguredBaseLayersPlugin = (t, isMobile) => {
  if (isMobile) {
    return {
      position: 'BR',
      layerId: 0,
      layerVisibility: true,
      collapsed: true,
      collapsible: true,
      columnsNumber: 3,
      empty: false,
      layerOpts: [
        {
          id: 'mapa',
          preview: MAPA,
          title: t('visor.street_map'),
          layers: [
            new M.layer.WMTS({
              url: 'https://www.ign.es/wmts/ign-base?',
              name: 'IGNBaseTodo',
              legend: t('visor.street_map'),
              matrixSet: 'GoogleMapsCompatible',
              transparent: false,
              displayInLayerSwitcher: false,
              queryable: false,
              visible: true,
              format: 'image/jpeg',
            }),
          ],
        },
        {
          id: 'raster',
          preview: RASTER,
          title: t('visor.map'),
          layers: [
            new M.layer.WMTS({
              url: 'https://www.ign.es/wmts/mapa-raster?',
              name: 'MTN',
              legend: t('visor.map'),
              matrixSet: 'GoogleMapsCompatible',
              transparent: false,
              displayInLayerSwitcher: false,
              queryable: false,
              visible: true,
              format: 'image/jpeg',
            }),
          ],
        },
        {
          id: 'imagen',
          preview: IMAGEN,
          title: t('visor.image'),
          layers: [
            new M.layer.WMTS({
              url: 'https://www.ign.es/wmts/pnoa-ma?',
              name: 'OI.OrthoimageCoverage',
              legend: t('visor.image'),
              matrixSet: 'GoogleMapsCompatible',
              transparent: false,
              displayInLayerSwitcher: false,
              queryable: false,
              visible: true,
              format: 'image/jpeg',
            }),
          ],
        },
      ],
    };
  } else {
    return {
      position: 'TR',
      layerId: 0,
      layerVisibility: true,
      collapsed: true,
      collapsible: true,
      columnsNumber: 3,
      empty: false,
      layerOpts: [
        {
          id: 'mapa',
          preview: MAPA,
          title: t('visor.street_map'),
          layers: [
            new M.layer.WMTS({
              url: 'https://www.ign.es/wmts/ign-base?',
              name: 'IGNBaseTodo',
              legend: t('visor.street_map'),
              matrixSet: 'GoogleMapsCompatible',
              transparent: false,
              displayInLayerSwitcher: false,
              queryable: false,
              visible: true,
              format: 'image/jpeg',
            }),
          ],
        },
        {
          id: 'raster',
          preview: RASTER,
          title: t('visor.map'),
          layers: [
            new M.layer.WMTS({
              url: 'https://www.ign.es/wmts/mapa-raster?',
              name: 'MTN',
              legend: t('visor.map'),
              matrixSet: 'GoogleMapsCompatible',
              transparent: false,
              displayInLayerSwitcher: false,
              queryable: false,
              visible: true,
              format: 'image/jpeg',
            }),
          ],
        },
        {
          id: 'imagen',
          preview: IMAGEN,
          title: t('visor.image'),
          layers: [
            new M.layer.WMTS({
              url: 'https://www.ign.es/wmts/pnoa-ma?',
              name: 'OI.OrthoimageCoverage',
              legend: t('visor.image'),
              matrixSet: 'GoogleMapsCompatible',
              transparent: false,
              displayInLayerSwitcher: false,
              queryable: false,
              visible: true,
              format: 'image/jpeg',
            }),
          ],
        },
      ],
    };
  }
}

const getConfiguredVectorsPlugin = (filter) => {
  let precharged = CONFIGURATIONS.precharged;
  if (filter !== undefined) {
      let groups = [];
      let services = [];
      if (CONFIGURATIONS.precharged.services !== undefined && CONFIGURATIONS.precharged.services.length > 0) {
        services = CONFIGURATIONS.precharged.services.filter((s) => {
          return s.tags.indexOf(filter) > -1;
        });
      }

      if (CONFIGURATIONS.precharged.groups !== undefined && CONFIGURATIONS.precharged.groups.length > 0) {
        const filtered = CONFIGURATIONS.precharged.groups.filter((g) => {
          return g.services.filter((s) => {
            return s.tags.indexOf(filter) > -1;
          }).length > 0;
        });

        filtered.forEach((g) => {
          const group = { name: g.name };
          const innerServices = g.services.filter((s) => {
            return s.tags.indexOf(filter) > -1;
          });
          group.services = innerServices;

          if (innerServices.length > 0) {
            groups.push(group);
          }
        });
      }

      precharged = {};
      if (groups.length > 0) {
        precharged.groups = groups;
      }

      if (services.length > 0) {
        precharged.services = services;
      }
  }

  return {
    collapsed: true,
    collapsible: true,
    position: 'TR',
    precharged: precharged,
    mobile: Utils.isMobile(),
  };
}

const loadGeoJSONLayer = (source, layerName, legend, t) => {
  let features = new ol.format.GeoJSON().readFeatures(source, { featureProjection: mapjs.getProjection().code });
  features = featuresToFacade(features);
  const layer = new M.layer.Vector({
    name: layerName,
    legend: legend || layerName,
    extract: true,
  });

  layer.addFeatures(features);
  mapjs.addLayers(layer);
  addMapVectorEvent(t);
  return features;
}

const loadKMLLayer = (source, layerName, extractStyles, legend, t) => {
  let features = new ol.format.KML({ extractStyles }).readFeatures(source, { featureProjection: mapjs.getProjection().code });
  features = featuresToFacade(features);
  features = geometryCollectionParse(features);
  const others = [];
  const lines = [];
  features.forEach((f) => {
    if (f.getGeometry().type.toLowerCase().indexOf('linestring') > -1) {
      lines.push(f);
    } else {
      others.push(f);
    }
  });

  if (lines.length > 0) {
    const legendAux = legend !== undefined ? `${legend} (Líneas)` : `${layerName}_lines`;
    const layer = new M.layer.Vector({
      name: `${layerName}_lines`,
      legend: legendAux,
      extract: true,
    });

    layer.addFeatures(lines);
    mapjs.addLayers(layer);
  }

  if (others.length > 0) {
    const layer = new M.layer.Vector({
      name: layerName,
      legend: legend || layerName,
      extract: true,
    });

    layer.addFeatures(others);
    mapjs.addLayers(layer);
  }

  addMapVectorEvent(t);
  return features;
}

const featuresToFacade = (implFeatures) => {
  return implFeatures.map((feature) => {
    return M.impl.Feature.olFeature2Facade(feature);
  });
}

const geometryCollectionParse = (features) => {
  const parsedFeatures = [];
  features.forEach((feature) => {
    if (feature.getGeometry().type === 'GeometryCollection') {
      const geometries = feature.getGeometry().geometries;
      geometries.forEach((geometry) => {
        const num = Math.random();
        const newFeature = new M.Feature(`mf${num}`, {
          type: 'Feature',
          id: `gf${num}`,
          geometry: {
            type: geometry.type,
            coordinates: geometry.coordinates,
          },
        });
        parsedFeatures.push(newFeature);
      });
    } else {
      parsedFeatures.push(feature);
    }
  });
  return parsedFeatures;
}

const manageFeatures = (features, service) => {
  if (!M.utils.isNullOrEmpty(features)) {
    const color = service.color !== undefined ? service.color : '#71a7d3';
    const size = service.size !== undefined ? service.size : 6;
    let strokeStyle = undefined;
    if (service.stroke !== undefined) {
      strokeStyle = service.stroke === 'dots' ? POINTS : service.stroke === 'lines' ? LINES : service.stroke === 'dots-lines' ? LINE_POINTS : undefined;
    }

    features.forEach((f) => {
      switch (f.getGeometry().type) {
        case 'Point':
        case 'MultiPoint':
          const newPointStyle = {
            radius: size,
            fill: {
              color,
            },
            stroke: {
              color: 'white',
              width: 2,
            },
          };

          if (f !== undefined) f.setStyle(new M.style.Point(newPointStyle));
          break;
        case 'LineString':
        case 'MultiLineString':
          const newLineStyle = new M.style.Line({
            stroke: {
              color,
              width: size,
              linedash: strokeStyle,
            },
          });

          if (f !== undefined) f.setStyle(newLineStyle);
          break;
        case 'Polygon':
        case 'MultiPolygon':
          const newPolygonStyle = new M.style.Polygon({
            fill: {
              color,
              opacity: 0.2,
            },
            stroke: {
              color,
              width: size,
              linedash: strokeStyle,
            },
          });

          if (f !== undefined) f.setStyle(newPolygonStyle);
          break;
        default:
          break;
      }
    });
  }
}

const addMapVectorEvent = (t) => {
  mapjs.getMapImpl().on('pointermove', (evt) => {
    const infoButton = document.querySelector('.m-control.m-container.m-information-container');
    if (infoButton !== null && infoButton.classList.contains('activated')) {
      mapjs.getMapImpl().forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
        removeMapOverlays(false);
        showInfoPopup(feature, evt.coordinate, t);
      });

      /*const features = mapjs.getMapImpl().getFeaturesAtPixel(evt.pixel);
      if (features.length === 0) {
        removeMapOverlays(false);
      }*/
    }
  });
}

const removeMapOverlays = (removeAll) => {
  const overlays = mapjs.getMapImpl().getOverlays().getArray();
  overlays.forEach(item => {
    if (removeAll) {
      if (item.getKeys().indexOf('element') > -1) {
        mapjs.getMapImpl().removeOverlay(item);
      }
    } else {
      if (item.getKeys().indexOf('element') > -1 && item.stopEvent) {
        mapjs.getMapImpl().removeOverlay(item);
      }
    }
  });
}

const showInfoPopup = (feature, coords, t) => {
  removeMapOverlays(false);
  const overlay = new ol.Overlay({
    element: getInfoPopupContent(feature, t),
    stopEvent: true,
    autoPan: false,
    offset: [0, -132],
    position: coords,
    positioning: 'center-center',
  });

  mapjs.getMapImpl().addOverlay(overlay);
  document.getElementById('overlay-close-button').addEventListener('click', removeMapOverlays.bind(null, false));
};

const getInfoPopupContent = (feature, t) => {
  const attributes = feature.getProperties();
  const rows = [];
  Object.keys(attributes).forEach((k) => {
    if (!M.utils.isNullOrEmpty(attributes[k]) && k !== 'geometry') {
      if (M.utils.isUrl(attributes[k])) {
        rows.push(
          <tr key={k}>
            <td>{k}</td>
            <td><a href={attributes[k]} target="_blank" rel="noopener noreferrer">{attributes[k]}</a></td>
          </tr>
        );
      } else {
        rows.push(
          <tr key={k}>
            <td>{k}</td>
            <td>{attributes[k]}</td>
          </tr>
        );
      }
    }
  });

  const htmlString = reactElementToJSXString(
    <div id='popup-overlay' class='popup-overlay-container'>
      <div class='popup-overlay-title'>
        {t('visor.information')}
        <button id='overlay-close-button' class='popup-overlay-close-button'>
          X
        </button>
      </div>
      <div class='popup-overlay-info-container'>
        <div>
          <table>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      </div>
      <div class='popup-overlay-down-arrow'>
        <img src={DownArrow} alt='Down arrow' />
      </div>
    </div>
  );

  const doc = new DOMParser().parseFromString(htmlString, 'text/html');
  return doc.getElementById('popup-overlay');
}
