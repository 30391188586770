export default {
  header: {
    title: 'Andalusia Roads (AR)',
  },
  visor: {
    coordinates: 'Coordinates',
    map: 'Map',
    image: 'Image',
    street_map: 'Street map',
    information: 'Information',
  },
};
